@font-face {
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}


.social-card {
    background: #EEF2F6;
    background: url('https://indyhall.org/assets/images/new-group.jpg') no-repeat top left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #EEF2F6;
    border: solid 1px #000;
    position: relative;
}

.header {
    width: 1024px;
    /*content: url('images/header.png');*/
    position: absolute;
    top: 0;
}

.footer {
    width: 1024px;
    /*content: url('images/footer.png');*/
    position: absolute;
    bottom: 0;
}

.social-card .logo {
    position: absolute;
    top: 50px;
}

.social-card .title {
    background-color: #F7D549;
    padding: 2px 2px;
    width: 100%;
    opacity: .9;
}

.social-card h1 {
    text-align: center;
    margin-left: 100px;
    margin-right: 100px;
    font-size: 2.5vw;
    line-height: 1.2em;
    font-weight: bolder;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    color: #1B202B;
}

.author {
    position: absolute;
    bottom: 45px;
    font-weight: bold;
}

.author img {
    width: 50px;
    border-radius: 100%;
    margin-bottom: -15px;
    margin-right: 10px;
}